import { ConnectInstagramService } from 'src/app/shared/connect-instagram.service';
import { Injectable } from '@angular/core';

import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  jwt = {
    decode: (token) => {
      return JSON.parse(atob(token.split('.')[1]));
    },
  };
  token;
  userData;
  pk;
  username;
  client;
  noClient = false;

  constructor(private connectIGService: ConnectInstagramService) {}

  setData(token, userData) {
    this.token = token;
    this.userData = userData;

    if(!userData) {
      return;
    }

    this.pk = this.userData.pk;
    this.username = this.userData.username;
    this.client = this.userData.client;

    const nonce = localStorage.getItem('nonce:' + this.pk);
    this.connectIGService.challengeNonce = nonce;

    this.identifySentry();
  }

  identifySentry() {
    Sentry.setUser({
      id: this.pk,
      username: this.username,
      client: this.client,
    });
  }
}
